import React from 'react'
import styles from './footer.module.css';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <h2 className={styles.logo}>Bujig.mn</h2>
        <p className={styles.slogan}>© 2023 Made in Zyber</p>
      </div>
      <div className={styles.item}>
        <div>
          <p>Холбоо барих: </p>
          <Link to={'https://www.facebook.com/70121015bujig/'}>
            <FontAwesomeIcon icon={faFacebook} className={styles.icon} />
          </Link>
          <Link to={'https://www.instagram.com/se_dance_production/'}>
            <FontAwesomeIcon icon={faInstagram} className={styles.icon} />
          </Link>
        </div>
        {/* <p>tsengee15@gmail.com</p> */}
      </div>
    </div>
  )
}

export default Footer
