import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Order = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        address: '',
        orderRecieve: '',
        orderDay: '',
        whoCreated: '',
        whoCountet: '',
        bankAccountPay: 0,
        cash: 0,
        card: 0,
        bankDescription: '',
        prePayment: '',
        rent: 0,
        collateral: 0,
        recieveClothes: '',
        safe: false,
        status: 'Авч явж байгаа',
        bankAccount: '',
        isback: false,
        orderId: '',
        clothes: []
    });

    useEffect(() => {
        const wishlistItems = localStorage.getItem('wishlistItems');
        if (wishlistItems) {
            const parsedWishlist = JSON.parse(wishlistItems);
            const clothes = parsedWishlist.map(item => ({
                code: item.title,
                img:
                    item.isHandle === true ?
                        `https://clothes.templateapi.xyz/${item.cover}` :
                        `https://clothes.templateapi.xyz/uploads/${item.title}.jpg`
                ,
                unit: 0,
                price: parseInt(item.price, 10),
                total: 0
            }));
            setFormData(prevState => ({
                ...prevState,
                clothes: clothes
            }));
        }
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleClothesChange = (index, e) => {
        const { name, value } = e.target;
        const clothes = [...formData.clothes];
        clothes[index][name] = parseInt(value, 10);
        clothes[index].total = clothes[index].unit * clothes[index].price;

        const totalRent = clothes.reduce((acc, item) => acc + item.total, 0);

        setFormData({
            ...formData,
            clothes: clothes,
            rent: totalRent
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/orders', formData);
            console.log('Order created:', response.data);
        } catch (error) {
            console.error('There was an error creating the order!', error);
        }
    };

    return (
        <div style={{ minHeight: '100vh', paddingTop: '100px' }}>
            <h2 style={{ paddingLeft: '30px' }}>Захиалга батлах</h2>
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '20px', justifyContent: 'center', alignItems: 'center' }}>
                <input type="text" placeholder='нэр' name="name" value={formData.name} onChange={handleChange} />
                <input type="text" placeholder='утас' name="phone" value={formData.phone} onChange={handleChange} />
                <input type="text" placeholder='хаяг' name="address" value={formData.address} onChange={handleChange} />
                <input type="text" placeholder='захиалгын өдөр' name="orderDay" value={formData.orderDay} onChange={handleChange} />
                <input type="text" placeholder='урьдчилгаа' name="prePayment" value={formData.prePayment} onChange={handleChange} />
                <label htmlFor="">
                    Түрээс:
                    <br />
                    <input type="number" placeholder='түрээс' name="rent" value={formData.rent} onChange={handleChange} />
                </label>
                <label htmlFor="">
                    Барьцаа:
                    <br />
                    <input type="number" placeholder='барьцаа' name="collateral" value={formData.collateral} onChange={handleChange} />
                </label>
                <input type="text" placeholder='банкны данс' name="bankAccount" value={formData.bankAccount} onChange={handleChange} />

                {formData.clothes.map((item, index) => (
                    <div key={item.code} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
                        <h4>{`Хувцасны код: ${item.code}`}</h4>
                        <img src={item.img} alt="" style={{ width: '80px' }} />
                        <label htmlFor="">

                            авах тоо ширхэг:
                            <br />
                            <input
                                type="number"
                                placeholder='тоо ширхэг'
                                name="unit"
                                value={item.unit}
                                onChange={(e) => handleClothesChange(index, e)}
                                style={{ marginBottom: '10px' }}
                            />
                        </label>
                        <input
                            type="number"
                            placeholder='нийт үнэ'
                            name="total"
                            value={item.total}
                            readOnly
                        />
                    </div>
                ))}

                <button style={{ width: 'max-content', borderRadius: '8px', padding: '20px' }} type="submit">Захиалга илгээх</button>
            </form>
        </div>
    )
}

export default Order;
