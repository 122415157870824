import React from 'react'
import styles from './age_filter.module.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AgeFilter = () => {
const { id } = useParams();
const navigate = useNavigate();

const age_kid = 'test_2';
const age_teen = 'summary';
const age_adult = 'contactEmail';


  return (
    <>
      <div className={styles.button_container}>
        <button onClick={() => navigate(-1)} className={styles.chevButton}>
          <FontAwesomeIcon icon={faChevronLeft} className={styles.icon} />
          Буцах
        </button>
      </div>
      <div className={styles.container}>
        <h2>Та насаа сонгон хувцасаа үзнэ үү</h2>
        <div className={styles.item}>
          <Link className={styles.link} to={'/post/'+id+'/'+age_kid}>цэцэрлэг болон бага анги 4-8 нас</Link>
        </div>
        <div className={styles.item}>
          <Link className={styles.link} to={'/post/'+id+'/'+age_teen}>6-14 нас</Link>
        </div>
        <div className={styles.item}>
          {/* <FontAwesomeIcon icon={faVenus} className={styles.icon} /> */}
          <Link className={styles.link} to={'/post/'+id+'/'+age_adult}>15-насанд хүрэгчид</Link>
        </div>
      </div>
    </>
  )
}

export default AgeFilter
