import React, { useContext, useState, useRef, useEffect } from 'react';
import { WebSocketContext } from './WebSocketContext';
import './App.css';
import { IoIosSend } from "react-icons/io";
import { FaRegCircleUser } from "react-icons/fa6";
import dayjs from 'dayjs';
import 'react-quill/dist/quill.snow.css';
import Editor from './Editor';

const Chat = ({ sender }) => {
    const { messages, sendMessage } = useContext(WebSocketContext);
    const [newMessage, setNewMessage] = useState('');
    const messagesEndRef = useRef(null);

    const handleSendMessage = () => {
        if (newMessage.trim() && sender.trim()) {
            const message = {
                sender: sender,
                receiver: 'sedance',
                content: newMessage,
                createdAt: new Date().toISOString(),
            };
            console.log('Sending message:', message);
            sendMessage(message);
            setNewMessage('');
        } else {
            console.error('Message or sender is empty');
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSendMessage();
        }
    };

    // useEffect(() => {
    //     messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    // }, [messages]);

    return (
        <div className="chat-container">
            <div className="user-info">
                <FaRegCircleUser size={25} color='grey' />
                <p>{sender}</p>
            </div>
            <div className="messages">
                {messages.slice().reverse()
                    .filter((e) => e.receiver === sender || e.sender === sender)
                    .map((msg, index) => (
                        <div key={index} className={`${msg.receiver === 'sedance' ? 'messagesRight' : 'messagesLeft'}`}>
                            {/* <div className={`message ${msg.receiver === 'sedance' ? 'right' : 'left'}`}>
                                {msg.content}
                            </div> */}
                            <div className={`message ${msg.sender === 'sedance' ? 'right' : 'left'}`}>
                                {/* {msg.content} */}

                                <div
                                    dangerouslySetInnerHTML={{ __html: msg.content }} />
                            </div>
                            <p className='message-info'>
                                {msg.sender} {dayjs(msg.createdAt).format('MM-DD HH:mm')}
                            </p>
                        </div>
                    ))}
                <div ref={messagesEndRef} />
            </div>
            <div className="message-input">
                {/* <textarea
                    cols={50} rows={1}
                    placeholder="Type a message..."
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyPress={handleKeyPress}
                    value={newMessage}
                ></textarea> */}
                <Editor value={newMessage} onChange={setNewMessage} />
                {/* <Editor /> */}
                <button onClick={handleSendMessage}>
                    <IoIosSend size={40} color='green' />
                </button>
            </div>
        </div>
    );
};

export default Chat;