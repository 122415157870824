import React, { useEffect, useState } from 'react';
import styles from './post.module.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Posts = () => {
  const [post, setPost] = useState([]);
  const [isFetch, setIsFetch] = useState(false);
  const { id, age } = useParams();
  const navigate = useNavigate();
  const [wishlistItems, setWishlistItems] = useState([]);
  const [isAdded, setIsadded] = useState(false);

  useEffect(() => {
    const storedWishlistItems = JSON.parse(localStorage.getItem('wishlistItems'));
    if (storedWishlistItems) {
      setWishlistItems(storedWishlistItems);
    }
  }, []);

  const addToWishlist = (item) => {
    if (!wishlistItems.find((wishlistItem) => wishlistItem._id === item._id)) {
      const newWishlistItems = [...wishlistItems, item];
      setWishlistItems(newWishlistItems);
      localStorage.setItem('wishlistItems', JSON.stringify(newWishlistItems));
      setIsadded(true);
    }
  };

  useEffect(() => {
    axios.get('https://clothes.templateapi.xyz/categories/' + id + '/post/age?sort=-createdAt')
      .then((e) => {
        setPost(e.data.data);
        setIsFetch(true);
      })
      .catch((error) => {
        setIsFetch(true);
        alert(error)
      });
  }, [id, age]);
  return (
    <>
      <div className={styles.button_container}>
        <button onClick={() => navigate(-1)} className={styles.chevButton}>
          <FontAwesomeIcon icon={faChevronLeft} className={styles.icon} />
          Буцах
        </button>
      </div>
      {isFetch === true ? post.length > 0 ?
        <div className={styles.container}>
          {post.filter((ev) => {
            return age === 'test_2' ? ev.test_2?.includes('true')
              : age === 'summary' ? ev.summary?.includes('true')
                : ev.contactEmail?.includes('true')
          }).map((e) => (
            <div className={styles.post}>
              <div className={styles.item}>
                {/* {e.title} */}
                <div to={`/postdetial/${e._id}`} className={styles.link}>
                  <img className={styles.img}
                    src={e.isHandle === true ?
                      `https://clothes.templateapi.xyz/${e.cover}` :
                      `https://clothes.templateapi.xyz/uploads/${e.title}.jpg`}
                    alt="" />
                  <Link to={`/postdetial/${e._id}`} className={styles.button}>Энд дараад үнэ,
                    бусад мэдээлэл харна уу</Link>
                  <button className={styles.button} onClick={() => addToWishlist(e)}>
                    {
                      wishlistItems.find((el) => el._id === e._id)?.title === e.title ? 'Сагсанд нэмэгдсэн' : 'Сагсанд нэмэх'
                    }
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div> :
        <div className={styles.fail}>
          <h2>Энэ хуудас хоосон байна</h2>
        </div> :
        <div className={styles.fail}>
          <h2>Уншиж байна...</h2>
        </div>}
    </>
  )
}

export default Posts
