import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Check the import here
// import Home from './Pages/Home/Home';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import './App.css';
import Posts from './Pages/Posts/Posts';
import PostDetial from './Pages/Posts/post_detial/Post_detial';
import FemaleCategory from './Pages/Category/femaleCategory';
import AgeFilter from './Pages/Posts/age_filter/AgeFilter';
import ChatPage from './Pages/Chat/ChatPage';
import { WebSocketProvider } from './WebSocketContext';
import WishList from './Pages/WishList/WishList';
import Order from './Pages/Order/Order';

function App() {
  return (
    <Router classname="container_app">
      <Navbar />
      <WebSocketProvider>
        <Routes>
          {/* <Route path={'/'} element={<Home />} /> */}
          {/* <Route path={'/'} element={<SexCategory />} /> */}
          <Route exact path={'/'} element={<FemaleCategory />} />
          {/* <Route exact path={'/male'} element={<MaleCategory />} /> */}
          <Route path={'/post/:id/:age'} element={<Posts />} />
          <Route path={'/postdetial/:id'} element={<PostDetial />} />
          <Route path={'/agefilter/:id'} element={<AgeFilter />} />
          <Route path={'/messenger'} element={<ChatPage />} />
          <Route path={'/wishlist'} element={<WishList />} />
          <Route path={'/order'} element={<Order />} />
        </Routes>
        <Footer />
      </WebSocketProvider>
    </Router>
  );
}

export default App;