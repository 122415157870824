import React, { useEffect, useState } from 'react'
import styles from './ChatPage.module.css';
import Chat from '../../Chat';
import axios from 'axios';

const ChatPage = () => {
    const [sender, setSender] = useState('');

    return (
        <>
            <div className={styles.container}>
                <label>
                    Утасны дугаар:
                    <br />
                    <input type="text" value={sender}
                        onChange={(e) => setSender(e.target.value)}
                        placeholder='Утасны дугаар' enterKeyHint='99******' />
                </label>
                <Chat sender={sender} />
            </div>
        </>
    )
}

export default ChatPage
