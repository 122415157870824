import React from 'react'
import { Link } from 'react-router-dom';
import styles from './navbar.module.css';
import { TiMessages } from "react-icons/ti";

const links = [
  {
    name: 'Нүүр',
    path: '/',
  },
  {
    name: 'Нүүр',
    path: '/',
  }
];

const Navbar = () => {
  return (
    <div className={styles.container}>
      <div className={styles.container_logo}>
        <Link className={styles.logo} to='/'>SE dance wear </Link>
        {/* <Link className={styles.logo_min} to='/'>Bujig.mn</Link> */}
      </div>
      <div className={styles.links}>
        <Link to={'/'} className={styles.link} >Нүүр</Link>
        <Link to={'/wishlist'} className={styles.link} >
          Сагс
        </Link>
      </div>
    </div>
  )
}

export default Navbar
