import React, { useEffect, useState } from 'react'
import styles from './post_detail.module.css';
import Sheet from 'react-modal-sheet';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'


const PostDetial = () => {
  const [isOpen, setOpen] = useState(false);
  const [post, setPost] = useState({});
  const [clotheslist, setClotheslist] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`https://clothes.templateapi.xyz/post/${id}`)
      .then((e) => setPost(e.data))
      .catch((error) => alert(error));
    axios.get(`https://clothes.templateapi.xyz/clotheslist`)
      .then((res) => setClotheslist(res.data.data))
      .catch((e) => alert(e));
  }, [id]);

  return (
    <>
      <div className={styles.button_container}>
        <button onClick={() => navigate(-1)} className={styles.chevButton}>
          <FontAwesomeIcon icon={faChevronLeft} className={styles.icon} />
          Буцах
        </button>
      </div>
      <div className={styles.container}>
        <div className={styles.item}>
          <p>{post.sex}</p>
          {/* <img className={styles.img} src={`https://clothes.templateapi.xyz/${post.cover}`} alt="" /> */}
          <img className={styles.img} src={
            post.isHandle === true ? `https://clothes.templateapi.xyz/${post.cover}` : `https://clothes.templateapi.xyz/uploads/${post.title}.jpg`
          } alt="" />
          {/* e.isHandle === true ? `https://clothes.templateapi.xyz/${e.cover}` : `https://clothes.templateapi.xyz/uploads/${e.title}.jpg` */}
        </div>
        <div className={styles.item_desc}>
          <p>Үнэ: {post.price} ( Түрээсийн хугацаа 24 цаг )</p>
          <p>Код: {post.title}</p>
          <p>Барьцаа: {post.description} ( Хувцсаа буцаан өгч барьцаа мөнгөө эргүүлэн авна )</p>
          <p>Тайлбар: {post.test_4}</p>
          <p>Нийт үлдэгдэл: {post.content}</p>
          {post.sizes ? <p style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>Хэмжээнүүд: {
            post.sizes.map((e) => (
              <div style={{ display: 'flex', gap: '10px', paddingLeft: '20px', color: '#838383' }}>
                <p>Хэмжээ: {e.size}</p>
                <p>Үлдэгдэл: {e.balance} ширхэг</p>
              </div>
            ))
          }</p> : null}
          <p>Одоогийн үлдэгдэл: {parseInt(post.content) - clotheslist.filter((e) => {
            return e.clothes.includes(post.title) && (
              e.status.toLowerCase().includes('захиалгатай')
              || e.status.toLowerCase().includes('буцаж ирээгүй')
              || e.status.toLowerCase().includes('Үйлчлүүлэгч авсан'.toLowerCase())
              || e.status.toLowerCase().includes('зарагдсан')
            )
          }).length} ширхэг</p>
          <p>Хувцас буцаж ирэх өдрүүд:</p>
          {clotheslist.filter((e) => {
            return e.clothes && e.clothes.includes(post.title) && (
              e.status.toLowerCase().includes('захиалгатай')
              || e.status.toLowerCase().includes('буцаж ирээгүй')
              || e.status.toLowerCase().includes('Үйлчлүүлэгч авсан')
              || e.status.toLowerCase().includes('зарагдсан')
            ) && e.uldegdel > 0
          })
            .map((e) =>
              <p> - {e.orderDate} </p>
            )}

          <p>Захиалгатай өдрүүд:</p>
          {clotheslist.filter((e) => e.clothes.includes(post.title) && (
            e.status.toLowerCase().includes('захиалгатай')
          ))
            .map((e) =>
              <p> - {e.preOrderDate} </p>
            )}
        </div>
        <button onClick={() => setOpen(true)} className={styles.button}>Холбоо барих</button>
        <Sheet
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          snapPoints={[500, 300, 100, 0]}
        >
          <Sheet.Container>
            <Sheet.Content>
              <h2 className={styles.title}>Захиалга өгөх</h2>
              <h3 className={styles.title}>Холбоо барих: {post.contactPhone}</h3>
              <div className={styles.container_modal}>
                <Link to={'https://www.facebook.com/70121015bujig/'} className={styles.item_modal}>
                  <FontAwesomeIcon to={'https://www.facebook.com/70121015bujig/'} icon={faFacebook} className={styles.icon} />
                  <p>Захиалга өгөх болон түрээслэх бол Facebook</p>
                </Link>
                <Link to={'https://www.instagram.com/se_dance_production/'} className={styles.item_modal}>
                  <FontAwesomeIcon to={'https://www.instagram.com/se_dance_production/'} icon={faInstagram} className={styles.icon} />
                  <p>Бичлэг зураг үзэх Instagram</p>
                </Link>
                <button onClick={() => setOpen(false)} className={styles.button}>Буцах</button>
              </div>
            </Sheet.Content>
          </Sheet.Container>
        </Sheet>
      </div>
    </>
  )
}

export default PostDetial
