import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';

export const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children, }) => {
    const [ws, setWs] = useState(null);
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        axios('https://clothes.templateapi.xyz/messages')
            .then((e) => setMessages(e.data));

        const websocket = new WebSocket('wss://clothes.templateapi.xyz');

        websocket.onopen = () => {
            console.log('WebSocket connected');
        };

        websocket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            setMessages((prevMessages) => [message, ...prevMessages]);
        };

        websocket.onclose = () => {
            console.log('WebSocket disconnected');
        };

        setWs(websocket);

        return () => {
            websocket.close();
        };
    }, []);

    const sendMessage = (message) => {
        if (ws) {
            ws.send(JSON.stringify(message));
        }
    };

    return (
        <WebSocketContext.Provider value={{ messages, sendMessage }}>
            {children}
        </WebSocketContext.Provider>
    );
};
