import React, { useEffect, useState } from 'react'
import styles from './index.module.css';
import { Link } from 'react-router-dom';

const WishList = () => {
    const [wishlistItems, setWishlistItems] = useState([]);
    const [removedItems, setRemovedItems] = useState([]);

    useEffect(() => {
        const storedWishlistItems = JSON.parse(localStorage.getItem('wishlistItems'));
        if (storedWishlistItems) {
            setWishlistItems(storedWishlistItems);
            wishlistItems.forEach((e) => {
                console.log(e);
            })
        }
    }, []);

    const deleteItem = (removeItem) => {
        const updatedWishlistItems = wishlistItems.filter(item => item._id !== removeItem._id);
        setWishlistItems(updatedWishlistItems);
        localStorage.setItem('wishlistItems', JSON.stringify(updatedWishlistItems));
    }

    return (
        <div style={{ minHeight: '100vh', paddingTop: '100px', display: 'flex', flexDirection: 'column', gap: '40px' }}>
            <h2 style={{ paddingLeft: '50px' }}>Таны сагсанд байгаа хувцаснууд</h2>
            <Link to={'/order'} className={styles.button} style={{ marginLeft: '50px', background: '#9EF9FF' }}>Захиалга өгөх</Link>
            {wishlistItems.length > 0 ?
                <div className={styles.container}>
                    {wishlistItems.map((e) => (
                        <div className={styles.post}>
                            <div className={styles.item}>
                                {/* {e.title} */}
                                <div to={`/postdetial/${e._id}`} className={styles.link}>
                                    <img className={styles.img}
                                        src={e.isHandle === true ?
                                            `https://clothes.templateapi.xyz/${e.cover}` :
                                            `https://clothes.templateapi.xyz/uploads/${e.title}.jpg`}
                                        alt="" />
                                    <Link to={`/postdetial/${e._id}`} className={styles.button}>Энд дараад үнэ,
                                        бусад мэдээлэл харна уу</Link>
                                    <button className={styles.button} onClick={() => deleteItem(e)}>
                                        {
                                            wishlistItems.find((el) => el._id === e._id)?._id === e._id ? 'Сагснаас хасах' : 'Сагсанд нэмэх'
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div> :
                <div className={styles.fail}>
                    <h2>Энэ хуудас хоосон байна</h2>
                </div>
            }
        </div>
    )
}

export default WishList
