import React, { useEffect, useState } from 'react';
import styles from './category.module.css';
import { Link } from 'react-router-dom';
import axios from 'axios';

const FemaleCategory = () => {
  const [category, setCategory] = useState([]);
  // const [filterCategory, setFilterCategory] = useState([]);
  // const [sex, setSex] = useState('');
  // const [age, setAge] = useState('');
  // const [type, setType] = useState('');
  // const sexHttp = 'sex=';
  // const typeHttp = 'name=';
  // const ageHttp = 'age=';

  useEffect(() => {
    axios.get(`https://clothes.templateapi.xyz/categories?sex=Эмэгтэй&sort=test_1`)
      .then((e) => setCategory(e.data.data))
      .catch((error) => alert(error));
    // axios.get( `https://clothes.templateapi.xyz/categories?sex=Эмэгтэй`)
    // .then((e) => setFilterCategory(e.data.data))
    // .catch((error) => alert(error));
  }, []);

  return (
    <>
      <div className={styles.container_select}>
      </div>
      <div className={styles.container}>
        {category.sort((a, b) => parseInt(a.test_1) > parseInt(b.test_1) ? 1 : -1).map(e => (
          <Link to={`/agefilter/${e.id}`} className={styles.link}>
            <p>{e.name}</p>
            {/* <p>нас:  {e.age}</p> */}
            {/* <p>хүйс:  {e.sex}</p> */}
          </Link>
        ))}
      </div>
    </>
  )
}

export default FemaleCategory
