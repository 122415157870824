import ReactQuill from "react-quill";

export default function Editor({ value, onChange }) {
    const modules = {
        toolbar: [
            // [{ header: [1, 2, false] }],
            // ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
                // { list: 'ordered' },
                // { list: 'bullet' },
                // // { indent: '-1' },
                // { indent: '+1' },
            ],
            ['image'],
            // ['clean'],
        ],
    };
    return (
        <div className="content">
            <ReactQuill
                style={{
                    width: window.innerWidth > 720 ? '20vw' : '80vw',
                    height: '20vh'
                }}
                // style={{ width: '40vw' }}
                value={value}
                theme={'snow'}
                onChange={onChange}
                modules={modules}
            />
        </div>
    );
}